import * as R from 'ramda'
import {ArrowBackIos, ArrowForwardIos} from '@mui/icons-material'
import loadable from '@loadable/component'
import React from 'react'
import Slider from 'react-animated-slider'

import {homepagePartnerSectionPropTypes} from 'helpers/propTypes'

import 'react-animated-slider/build/horizontal.css'
import useIsMobile from 'hooks/useIsMobile'
import useStyles from './styles'

const CarouselPartners = ({data, countryData, isBrands}) => {
  const Media = loadable(() => import('components/UI/Media'))
  const Title = loadable(() => import('components/UI/Title'))
  const {countryCode, navLocale} = countryData
  const classes = useStyles()
  const isMobile = useIsMobile()
  const mapIndexed = R.addIndex(R.map)
  const partnerList = R.splitEvery(3, data)
  const mobilePartnerList = R.splitEvery(1, data)

  return (
    <div className={classes.sliderContainer}>
      <Slider
        autoplay={2500}
        previousButton={<ArrowBackIos />}
        nextButton={<ArrowForwardIos />}
      >
        {mapIndexed(
          (splitedPartners, index) => (
            <div className={classes.partnersContainer} key={index}>
              {R.map(partner => {
                const partnerLink = R.equals(countryCode, 'worldwide')
                  ? `/worldwide/playbook#/partner-${partner.name}`
                  : `/${countryCode}/${navLocale}/playbook#/partner-${partner.name}`

                const brandLink = partner.website

                return (
                  <a
                    className={classes.cardContainer}
                    href={isBrands ? brandLink : partnerLink}
                    alt={partner.name}
                  >
                    <div className={classes.imageContainer}>
                      <Media data={partner.logo} alt={partner.name} />
                    </div>
                    <div className={classes.titlePartner}>
                      <Title
                        isCentered
                        variant="h3"
                        type={isMobile ? 'verySmallTitle' : 'innerTitle'}
                      >
                        {partner.name}
                      </Title>
                    </div>
                  </a>
                )
              }, splitedPartners)}
            </div>
          ),
          isMobile ? mobilePartnerList : partnerList,
        )}
      </Slider>
    </div>
  )
}

CarouselPartners.propTypes = homepagePartnerSectionPropTypes.isRequired

export default CarouselPartners
